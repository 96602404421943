<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.product_detail") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3"></div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <!-- <export-excel v-can="'patients.excel'" class="btn excel_btn" :data="model" :fields="excel_fields" @fetch="controlExcelData()"
                        worksheet="Пациенты " name="Пациенты.xls">
                        <el-button size="mini" icon="el-icon-document-delete">
                            Excel
                        </el-button>
                </export-excel> -->
          <router-link
            :to="{
              name: 'stocksWarehouses.index',
              params: { warehouse_id: warehouseId },
            }"
          >
            <el-button
              class="mr-2"
              size="small"
              icon="el-icon-arrow-left"
              type="primary"
            >
              {{ $t("message.go_back") }}</el-button
            >
          </router-link>
          <crm-column-settings
            :columns="columns"
            :modelName="'warehouseProducts'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <el-card class="box-card mb-2">
        <div
          style="
            padding: 10px 0px;
            margin-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
          "
        >
          <el-row :gutter="20">
            <el-col :sm="12">
              <div>
                <b>{{ productName }}</b>
              </div>
            </el-col>
            <el-col :sm="12">
              <div class="text-right">
                <span>{{ $t("message.code") }}:</span>
                <b class="ml-3"></b>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="20">
          <el-col :span="6">
            <div>
              <span>{{ $t("message.available") }}:</span>
              <b class="ml-3">{{ remainder }}</b>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>{{ $t("message.measurement") }}:</span>
              <b class="ml-3">{{ productCategory }}</b>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>{{ $t("message.cost_amount") }}:</span>
              <b class="ml-3">{{ totalAmount | formatMoney }}</b>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <span>{{ $t("message.sale_amount") }}:</span>
              <b class="ml-3">{{ newPrice | formatMoney }}</b>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.warehouse_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.available"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.day_in_warehouse"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.rate"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.price"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.price_all"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.percent"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.new_price"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.new_price_all"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.manufactured_date"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show"></th>
            <th v-if="columns.warehouse_id.show">
              <el-select
                v-if="!warehouseId"
                filterable
                clearable
                :placeholder="columns.warehouse_id.title"
                size="mini"
                v-model="filterForm.warehouse_id"
              >
                <el-option
                  v-for="item in warehouses"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.available.show"></th>
            <th v-if="columns.day_in_warehouse.show"></th>
            <th v-if="columns.rate.show"></th>
            <th v-if="columns.price.show"></th>
            <th v-if="columns.price_all.show"></th>
            <th v-if="columns.percent.show"></th>
            <th v-if="columns.new_price.show"></th>
            <th v-if="columns.new_price_all.show"></th>
            <th v-if="columns.manufactured_date.show">
              <el-date-picker
                :placeholder="columns.manufactured_date.title"
                v-model="filterForm.manufactured_date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                size="mini"
              ></el-date-picker>
            </th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="(models, index) in model"
            :key="models.id + '-' + index + ' - model names'"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ ++index }}</td>
            <td v-if="columns.warehouse_id.show">
              {{ models.warehouse ? models.warehouse.name : "" }}
            </td>
            <td v-if="columns.available.show">{{ models.remainder }}</td>
            <td v-if="columns.day_in_warehouse.show">
              {{ models.received_date }}
            </td>
            <td v-if="columns.warehouse_id.show">{{ models.rate }}</td>
            <td v-if="columns.price.show">{{ models.price }}</td>
            <td v-if="columns.price_all.show">
              {{ models.price * models.remainder }}
            </td>
            <td v-if="columns.percent.show">{{ models.percent }}</td>
            <td v-if="columns.new_price.show">{{ models.new_price }}</td>
            <td v-if="columns.new_price_all.show">
              {{ models.new_price * models.remainder }}
            </td>
            <td v-if="columns.manufactured_date.show">
              {{ models.manufactured_date }}
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "productResidues",
  data() {
    return {
      loadingData: false,
      warehouseId: this.$route.params ? this.$route.params.warehouse_id : "",
      selectedModel: {},
      filterForm: {
        id: "",
        manufactured_date: "",
        warehouse_id: "",
      },
      model: {},
    };
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    getWarehouseId() {
      return this.$route.params.warehouse_id;
    },
    ...mapGetters({
      columns: "warehouseProducts/columns",
      pagination: "warehouseProducts/pagination",
      filter: "warehouseProducts/filter",
      sort: "warehouseProducts/sort",
      warehouses: "warehouses/list",
    }),
    totalAmount: function () {
      return _.sumBy(this.model, function (o) {
        let product_total_amount = +o.price * +o.remainder;
        return _.round(product_total_amount, 2);
      });
    },
    remainder: function () {
      return _.sumBy(this.model, function (o) {
        let product_total_amount = o.remainder;
        return _.round(product_total_amount, 2);
      });
    },
    productName: function () {
      let name = this.model[0] ? this.model[0].product.name : "";
      return name;
    },
    productCategory: function () {
      let name = this.model[0]
        ? this.model[0].product
          ? this.model[0].product.category.name
          : ""
        : "";
      return name;
    },
    newPrice: function () {
      return _.sumBy(this.model, function (o) {
        let product_total_amount = +o.new_price * +o.remainder;
        return _.round(product_total_amount, 2);
      });
    },
  },

  async mounted() {
    if (this.warehouses && this.warehouses.length === 0)
      await this.loadWarehouse();
  },

  beforeDestroy() {
    this.warehouseId = "";
  },

  methods: {
    ...mapActions({
      updateSort: "warehouseProducts/updateSort",
      updateFilter: "warehouseProducts/updateFilter",
      updateColumn: "warehouseProducts/updateColumn",
      updatePagination: "warehouseProducts/updatePagination",
      editModel: "warehouseProducts/show",
      empty: "warehouseProducts/empty",
      delete: "warehouseProducts/destroy",
      refreshData: "warehouseProducts/refreshData",
      loadWarehouse: "warehouses/index",
    }),
    fetchData() {
      if (this.warehouseId != "") {
        if (!this.loadingData) {
          this.filterForm.getId = this.getId;
          this.filterForm.warehouseid = this.warehouseId;
          this.loadingData = true;
          this.editModel(this.filterForm)
            .then((res) => {
              this.loadingData = false;
              this.model = res.data.warehouseProduct.data;
            })
            .catch((err) => {
              this.loadingData = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      } else {
        if (!this.loadingData) {
          this.filterForm.getId = this.getId;
          this.loadingData = true;
          this.editModel(this.filterForm)
            .then((res) => {
              this.loadingData = false;
              this.model = res.data.warehouseProduct.data;
            })
            .catch((err) => {
              this.loadingData = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      }
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
  },
};
</script>